/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import './reset.css'
import './layout.scss'

const DEFAULT_TITLE = 'Vad ska vi äta?'

const Layout = ({ children, title, style }) => {
  const pageTitle = title ? `${title} ・ ${DEFAULT_TITLE}` : DEFAULT_TITLE

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
          rel="stylesheet"
        />
        <title>{pageTitle}</title>
      </Helmet>
      <main style={style || {}}>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
